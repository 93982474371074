import './index.scss'
import '../../assets/scripts/jquery.nicescroll.min'
import '../../assets/scripts/hScroll.js'


if (window.devicePixelRatio < 2) {
  if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
    $('.ny-banner .banner-desc').niceScroll({
      scrollspeed: 80,
      zindex: 1,
      cursorcolor: '#ccc',
      cursorborder: '1px solid #ccc',
      cursorwidth: 3,
      cursoropacitymax: 0.3,
      smoothscroll: true
    });

    $('.mob-nav').niceScroll({
      scrollspeed: 80,
      zindex: 1,
      cursorcolor: '#ccc',
      cursorborder: '1px solid #ccc',
      cursorwidth: 3,
      cursoropacitymax: 0.3,
      smoothscroll: true
    });
  }
}


// 二级菜单加箭头
!(function () {
  let elArray = $('.subnav-c')
  for (let i = 0; i < elArray.length; i++){
    if ($(elArray[i]).find('a').length > 0){
      $(elArray[i]).closest('.subnav-item').addClass('hassub')
    }
  }
})()


// 点击打开移动端导航栏
$('.mob-menu-icon').on('click',function () {
    $('.mob-nav').addClass('show')
    $('.mob-nav-mask').fadeIn(200)
})
$('.mob-nav .close-mob-nav').on('click', function () {
  $('.mob-nav').removeClass('show')
  $('.mob-nav-mask').fadeOut(200)
})
$('.mob-nav-mask').on('click', function () {
  $('.mob-nav').removeClass('show')
  $('.mob-nav-mask').fadeOut(200)
})

//点击移动端导航列表展开子项
$('.mob-nav ul li .menu-title .iconguanbi1').on('click',function () {
    let El = $(this).parents('li')
    $(El).hasClass('active')
    if ($(El).hasClass('active')) {
        $(El).removeClass('active').find('.subnav').slideUp(450);
    }else{
        $(El).addClass('active').siblings().removeClass('active')
        $(El).find('.subnav').slideDown(450);
        $(El).siblings().find('.subnav').slideUp(450);
    }
  if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      setTimeout(() => {
        $('.mob-nav').getNiceScroll().resize()
      }, 500);
    }
  }
})
$('.mob-nav ul li .subnav-item .menu-title2 .iconshang').on('click', function () {
  let hass = $(this).parents('.subnav-item ').find('.subnav-c')
  if(hass.length > 0 ){
    let El = $(this).parents('.subnav-item')
    if ($(El).hasClass('active')) {
      $(El).removeClass('active').find('.subnav-c').slideUp(450);
    } else {
      $(El).addClass('active').siblings().removeClass('active')
      $(El).find('.subnav-c').slideDown(450);
      $(El).siblings().find('.subnav-c').slideUp(450);
    }
  }
  if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      setTimeout(() => {
        $('.mob-nav').getNiceScroll().resize()
      }, 500);
    }
  }
})

//页面滚动监听

if ($('.left-nav').length > 0){
  var leftNavTop = $('.left-nav').offset().top;
  var leftNavH = $('.left-nav').outerHeight();
}
console.log('36', leftNavTop);
var beforeScroH = $(document).scrollTop();
var isScrollingToAnchor = false; // 添加标志变量
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var footerH = $('.root-footer').height(); //底部高度

  if (scroH > 50) {
    $('.header').addClass('style2')
  } else {
    $('.header').removeClass('style2')
  }
  if (scroH > 300) {
    $('.root-footer .back-top').fadeIn(500);
  } else {
    $('.root-footer .back-top').fadeOut(500);
  }
  if (contentH - (scroH + viewH) <= footerH - 30) {
    $('.root-footer .back-top').addClass('static')
  }else{
    $('.root-footer .back-top').removeClass('static')
  }


  // if ($(window).width() < 767) {
  //   console.log(1);
  // } 
  // else 
  if($('.inter_version').length > 0){
    var headerHeight = $('.header').outerHeight();
    var tabVersionOffset = $('.tab_version').offset().top;
    var scrollToptab = $(window).scrollTop();
    var delta = scroH - beforeScroH;
    console.log('标志的变量',isScrollingToAnchor);
     // 判断是否正在滚动到锚点链接
      if (isScrollingToAnchor) {
        return; // 如果是，则不执行后续逻辑
      }
    if (delta > 0) {
      if (scrollToptab >= tabVersionOffset - headerHeight) {
        $('.tab_version').addClass('headfixed');
        $('.header').fadeOut();
      }
      if (scroH > 300) {
        $('.root-header .header').addClass('hide')
      }
    } else if (delta < 0) {
      // console.log('向上滑');
      $('.tab_version').removeClass('headfixed');
      $('.header').fadeIn();
      $('.root-header .header').removeClass('hide')
    }
    beforeScroH = scroH;
  } else {
    //判断页面是上滚还是下滚
   // 0913
   var headerHeight = $('.header').outerHeight();
   var tabVersionOffset = $('.tab_version').offset().top;
   var scrollToptab = $(window).scrollTop();
   // 带头部的
   // if (scrollToptab >= tabVersionOffset - headerHeight) {
   //   $('.tab_version').addClass('fixed');
   // } else {
   //   $('.tab_version').removeClass('fixed');
   // }
 
   // 不带头部的
  //  if (scrollToptab >= tabVersionOffset - headerHeight) {
  //    $('.tab_version').addClass('headfixed');
  //    $('.header').fadeOut();
  //  } else {
  //    $('.tab_version').removeClass('headfixed');
  //    $('.header').fadeIn();
  //  }
  var delta = scroH - beforeScroH;
  if (delta > 0) {
    if (scrollToptab >= tabVersionOffset - headerHeight) {
      $('.tab_version').addClass('headfixed');
      $('.tab_version_son').addClass('fixeds');
      $('.header').fadeOut();
    }
    if (scroH > 300) {
      $('.root-header .header').addClass('hide')
    }
  } else if (delta < 0) {
    // console.log('向上滑');
    $('.tab_version').removeClass('headfixed');
    $('.tab_version_son').removeClass('fixeds');
    $('.header').fadeIn();
    $('.root-header .header').removeClass('hide')
  }
  beforeScroH = scroH;
  }

  // 监听锚点链接的点击事件
$('a[href^="#"]').click(function() {
  var target = $(this.hash);
  if (target.length) {
    isScrollingToAnchor = true; // 设置标志变量为true
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 1000, function() {
      isScrollingToAnchor = false; // 滚动到锚点链接结束后，将标志变量设置为false
    });
    return false;
  }
});

  // 关于我们右侧浮动文字
  if ($('.right-fixed-text')){
    $('.right-fixed-text').css("margin-top", - scroH / 2);
  }

  let winWidth = window.screen.width
  //内页左侧导航
  if ($('.left-nav').length > 0) {

    if (winWidth >= 768) {
      if (scroH > leftNavTop - 120 && contentH - (scroH + viewH) + 150 >= footerH) {
        $('.left-nav').addClass('fixed').removeClass('bottom')
      } else if (scroH <= leftNavTop - 120) {
        $('.left-nav').removeClass('fixed')
      } else if (contentH - (scroH + viewH) + 150 < footerH) {
        $('.left-nav').addClass('bottom').removeClass('fixed')
      }
    }
  }

  if ($('.hide-left-nav').length > 0 && winWidth >= 768){
    let hideTop = $('.hide-left-nav').offset().top

    if (scroH > hideTop - viewH/2){
      $('.left-nav').addClass('hide-nav')
    }else{
      $('.left-nav').removeClass('hide-nav')
    }

  }
});

$('.tab_version .ver_cont li').on('click',function () {
  $(this).addClass('active').siblings('li').removeClass('active')
})

//内页左侧导航点击

if ($('.left-nav').length > 0) {
  let el = $('.left-nav ul li')
  for(let i = 0; i < el.length; i++){
    if($(el[i]).find('.subnav a').length > 0){
      $(el[i]).addClass('hassub')
    }
  }
  // console.log('el',el)
  $.each(el,function(index,value){
    console.log("value",(value))
    if ($(value).hasClass('active')) {
      
      console.log("hasClass('active')："+index,$(value).hasClass('active'))
      $(value).slideDown(450);
      $(value).find('.subnav').css({'display': 'block'});
    }
  })


  $('.left-nav .nav-title .iconfont').on('click',function () {
    let parentsEl = $(this).parents('li')
    if ($(parentsEl).hasClass('active')) {
      $(parentsEl).removeClass('active').find('.subnav').slideUp(200);
    } else {
      $(parentsEl).addClass('active').siblings().removeClass('active')
      $(parentsEl).find('.subnav').slideDown(450);
      $(parentsEl).siblings().find('.subnav').slideUp(450);
    }
  })
}
// 0914
$(document).ready(function() {
  var ul = $('.tab_version .ver_cont ul');
  var liCount = ul.find('li').length;

  // if (liCount > 5) {
  //   ul.addClass('bigfour').removeClass('smallfour');
  // } else {
  //   ul.addClass('smallfour').removeClass('bigfour');
  // }

   if (liCount > 5) {
    ul.addClass('smallfour').removeClass('bigfour');
  } else {
    ul.addClass('smallfour').removeClass('bigfour');
  }

  $(".tab_version .ver_cont ul li").hover(function(){
    $(this).find('.version_down').stop().slideDown();
  },function(){
    $(this).find('.version_down').stop().slideUp();
  });

   //初始化
   new hScroll({
    nav1: '.inter_version .ver_cont .smallfour li', //导航对应的元素
    nav2: '.inter_version_item', //监听的元素
    checkClass: 'active' //选中的样式
});
});