module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '\r\n<footer class="root-footer">\r\n  <div class="footer-section1">\r\n    <div class="container w1200">\r\n      <div class="left">\r\n        <div class="icon-item">\r\n          <div class="icon"><a href="#" target="_blank"><i class="iconfont iconweibo"></i></a></div>\r\n        </div>\r\n        <div class="icon-item">\r\n          <div class="icon"><i class="iconfont iconweixin"></i></div>\r\n          <div class="icon-img">\r\n            <img src="' +
((__t = (require('./images/wx_ewm.png'))) == null ? '' : __t) +
'" alt="">\r\n          </div>\r\n        </div>\r\n        <div class="icon-item">\r\n          <div class="icon"><a href="#" target="_blank"><i class="iconfont icondouyin"></i></a></div>\r\n          <div class="icon-img">\r\n            <img src="' +
((__t = (require('./images/dy_ewm.png'))) == null ? '' : __t) +
'" alt="">\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class="right">\r\n        <a href="#" target="_blank">隐私条款</a>\r\n        <a href="#" target="_blank">版权声明</a>\r\n        <a href="javascript:void(0);" data-open="map">网站地图</a>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="footer-section2">\r\n    <div class="container w1200">\r\n      <div class="footer-logo"><img src="' +
((__t = (require('./images/footer_logo.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n      <div class="footer-location">\r\n        <p>青岛耶胡迪梅纽因学校</p>\r\n        <p>青岛西海岸新区银沙滩路299号（唐岛湾滨海公园2号门）</p>\r\n        <p><a href="tel:+157 0639 1303">157 0639 1303</a></p>\r\n      </div>\r\n      <div class="copyright">©2020 青岛耶胡迪梅纽因学校 版权所有 All Rights Reserved.<a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备05018705号</a></div>\r\n    </div>\r\n  </div>\r\n  <div class="back-top">\r\n    <div class="icon"><img src="' +
((__t = (require('./images/footer_icon_top.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n    <div class="text">TOP</div>\r\n  </div>\r\n</footer>';

}
return __p
}